body {
    margin-top: 85px !important;
}

#qrCodeImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
}

#donateCC{
    align-self: center;
    font-size: larger;
    margin-left: 25px;
}

.backToCampaignsLink {
    margin-right: 18px;
    padding: 7px 18px 7px 0;
    background: rgba(255,255,255,.1);
    border-radius: 15px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 3%), inset 0 2px 1px rgb(255 255 255 / 80%), inset 0 40px 40px rgb(255 255 255 / 24%), inset 0 -3px 6px rgb(255 255 255 / 24%), inset 0 -6px 18px rgb(255 255 255 / 24%), inset 0 6px 12px rgb(255 255 255 / 40%);
    backdrop-filter: blur(40px);
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: grid;
    text-align: center;
    letter-spacing: 0.05em;
    color: #e74f3a;
    white-space: nowrap;
    transition: all .3s ease-in-out;
    max-width: fit-content;
}

.backToCampaignsDiv{
    margin-top: -50px;
    margin-left: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#backToCampaignsChevron{
    align-self: center;
    color: #3F5663;
    margin-left: 10px;
    margin-right: 10px;
}

.backToCampaigns{
    width: fit-content;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    padding-left: 10px;
    color: #3F5663;
}

#imgCol{
    margin-right: 30px;
}

#mainContainer{
    background-color: rgba(255,255,255,.1) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03), inset 0px 2px 1px rgba(255, 255, 255, 0.8), inset 0px 40px 40px rgba(255, 255, 255, 0.24), inset 0px -3px 6px rgba(255, 255, 255, 0.24), inset 0px -6px 18px rgba(255, 255, 255, 0.24), inset 0px 6px 12px rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(40px);
    transform: translate3d(0, 0, 0);
    padding: 38px 38px 0;
    border-radius: 30px  !important;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #EDEEF1;
}

#topRow{
    padding: 20px 0;
}

#mainImage{
    width: 100%
}

#title{
    padding: inherit;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Primary Text - Grey 900 */
    color: #0E161C;
}
#titleDonation{
    padding: inherit;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Primary Text - Grey 900 */
    color: #0E161C;
}

#progressRow{
    padding-top: 20px;
}
#progressBar{
    width: 100%;
}

#acceptingRow {
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* identical to box height, or 20px */
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #3F5663;
}

.coinHelper{
    text-transform: none;
}

.coinHelper a{
    color: #3F5663;
    font-weight: 100;
    font-family: sans-serif;
}

.coinHelper a:hover{
    color: #3F5663;
    font-weight: 100;
    font-family: sans-serif;
    text-decoration: underline;
}

#acceptingDiv{
    margin-right: 30px;
}

.coinRewardInfo{
    font-family: sans-serif;
    font-weight: 100;
}

.mb-3.input-group{
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
}
.mb-1.input-group{
    display: inline-grid;
    grid-template-columns: 100px;
}

#countryRow h2 {
    font-family: Open Sans;
    font-style: normal;
    margin-bottom: 0;
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #3F5663;
}
#recurringRow{
    margin-top: 20px;
}
#donateRow{
    margin-top: 20px;
}
#donateButtonsRow{
    margin-top: 20px;
}
#donateAmount{
    display: inline-block;
    height: 44px;
    width: 100%;
    border: 1.5px solid #E5E5E5;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-radius: 4px 0 0 4px;
}
#tipAmount{
    display: inline-block;
    height: 44px;
    width: 100%;
    border: 1.5px solid #E5E5E5;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-radius: 4px 0 0 4px;
}
#totalAmount{
    display: inline-block;
    height: 44px;
    width: 100%;
    border: 1.5px solid #E5E5E5;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-radius: 4px 0 0 4px;
}
#recurringAmount{
    display: inline-block;
    height: 44px;
    width: 100%;
    border: 1.5px solid #E5E5E5;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-radius: 4px 0 0 4px;
}

#donateButton{
    display: inline-block;
    height: 45px;
    background: #E63C36;
    border: 1.5px solid #E63C36;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius:  0 4px 4px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    /* identical to box height, or 165% */
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: nowrap;
    z-index: auto;
    width: 175px;
}

#recurringButton {
    display: inline-block;
    height: 45px;
    background: #E63C36;
    border: 1.5px solid #E63C36;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius:  0 4px 4px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    /* identical to box height, or 165% */
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: nowrap;
    z-index: auto;
    width: 175px;
}

#giftIcon{
    margin-left: 7px;
    margin-top: -6px;
}

#videoPlayer{
    margin: 0 auto 20px auto;
}

#descriptionRow {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* or 20px */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    color: #3F5663;
}

@media (max-width: 992px){
    #videoPlayer{
        width: inherit !important;
    }
}

@media (max-width: 767px){
    #imgCol{
        min-width: 100%;
        margin-bottom: 20px;
    }
    #videoPlayer{
        height: 300px !important;
    }
}

@media  (max-width: 530px) {
    #videoPlayer{
        height: 260px !important;
    }
}

@media (max-width: 470px){
    #recurringRow {
        width: 95%;
    }
    #donateRow{
        width: 95%;
    }
    #donateButtonsRow{
        width: 95%;
    }
    #videoPlayer{
        height: 300px;
    }
    #videoPlayer{
        height: 200px !important ;
    }
}

@media (max-width: 470px){
    #donateAmount{
        min-width: 20px;
    }
    #tipAmount{
        min-width: 20px;
    }
    #totalAmount{
        min-width: 20px;
    }
    #videoRowContainer{
        padding-left: 0;
    }
}

@media (max-width: 391px){
    #recurringRow {
        min-width:200px;
        width:100%;        
    }
    #donateRow{
        min-width:200px;
        width:100%;
    }
    #donateButtonsRow{
        min-width:200px;
        width:100%;
    }
    .mb-3.input-group{
        display:inline-block;
    }
    .mb-1.input-group{
        display:inline-block;
    }
    #donateButton {
        margin-top: 1rem;
        border-radius: 4px;
    }
    #recurringButton {
        margin-top: 1rem;
        border-radius: 4px;
    }
    #donateAmount{
        text-align: center;
    }
    #tipAmount{
        text-align: center;
    }
    #recurringAmount{
        text-align: center;
    }
    #totalAmount{
        text-align: center;
    }
}
