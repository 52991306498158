.App {
  font-family: sans-serif;
  text-align: center;
  max-width: 540px;
  margin: 0 auto;
}

.scrollableContainer {
  position: relative;
  height: auto;
}

.list {
  display: flex;
  overflow-x: auto;
  list-style: none;
  height: auto;
}
.list1 {
  display: flex;
  overflow-x: auto;
  list-style: none;
  height: auto;
  overflow: hidden;
}


.item {
  display: flex;
  width: 22rem;
  height: auto;
  background-color: rgba(34, 157, 116, 0.163);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.item:not(:last-child) {
  margin-right: 1em;
}

.shadowWrapper {
  width: 30px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  top: 0;
}

.leftShadowWrapper {
  left: 0;
}

.rightShadowWrapper {
  right: 0;
}

.shadow {
  position: absolute;
  box-shadow: 0 0 30px -8px #232628;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -25px;
  width: 24px;
  height: 80%;
  border-radius: 50%;
  transform: translateY(-50%);
}

.leftShadow {
  box-shadow: 0 0 30px -8px #232628;
  left: -25px;
}

.rightShadow {
  box-shadow: 0 0 30px -8px #232628;
  right: -25px;
}

.shadow--hidden {
  display: none;
}

.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 2;
}

.buttonLeft {
  left: -20px;
}

.buttonRight {
  right: -20px;
}

.button--hidden {
  display: none;
}
