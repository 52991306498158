.modal-content{
    width: 80% !important;
    text-align: center;
}

.modal-content1{
    width: 80%;
    text-align: center;
}

.modalIcon {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.modalTitle {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: #0E161C;
    margin-bottom: 1.5rem;
}

.modalMessage {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    letter-spacing: 0.005em;
    color: #0E161C;
    margin-bottom: 1.5rem;
}

.modal-body{
    padding: 5rem !important;
}