#topRow {
    padding: 20px 0;
}

#buyRow {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* or 20px */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    color: #3F5663;
}
#tableHeaderRow {
    margin-top: 20px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* or 20px */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    color: #3F5663;
}

#investmentsHeaderRow {
    margin-top: 20px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* or 20px */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    color: #3F5663;
}

#tableHeaderRow {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143.32%;
    /* or 20px */
    letter-spacing: 0.005em;
    /* Frequently Used/Text/Secondary Text - Grey 700 */
    color: #3F5663;
}

#mainContainer{
    border: 1px solid #EDEEF1;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(238, 239, 242, 0.65);
    border-radius: 4px;
    padding: 20px 50px;
    width: 95%;
}

#amount{
    display: inline-block;
    height: 44px;
    width: 100%;
    border: 1.5px solid #E5E5E5;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border-radius: 4px 0 0 4px;
}

#buyButton{
    display: inline-block;
    height: 45px;
    width: 100%;
    background: #E63C36;
    border: 1.5px solid #E63C36;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius:  0 4px 4px 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
    /* identical to box height, or 165% */
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #FFFFFF;
    white-space: nowrap;
    z-index: auto;
}