#finishedCampaignsMainDiv {
    background: rgba(255,255,255,.1);
    display: grid;
    grid-column-gap: 14px;
    width: 100%;
    margin-bottom: 20px;
}


#finishedCampaignsMainDiv .card {
    display: inline-block;
}
  
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 768px) 
and (-webkit-min-device-pixel-ratio: 2) 
and (orientation: portrait) {
    #finishedCampaignsMainDiv {
        grid-template-columns: repeat(1,1fr);
    }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px)  
and (orientation: portrait) {
    #finishedCampaignsMainDiv {
        grid-template-columns: repeat(2,1fr);
    }
}

@media only screen and (min-width: 1025px) {
    #finishedCampaignsMainDiv {
        grid-template-columns: repeat(3,1fr);
    }
}

#finishedCampaignsMainDiv .card-img {
    width: 116px;
}

#finishedCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;
    -webkit-box-pack: justify;
}

#finishedCampaignsMainDiv .card-title.h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #0E161C;
}