.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
    display: inline-block;
    }
  
  .RichEditor-styleButton {
    color: #0E161C;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
  }

  .bold{
    display: inline-block;
    font-weight: 800;
  }

  .italic{
    display: inline-block;
    font-style: italic;
  }

  .underline{
    display: inline-block;
    text-decoration: underline !important;
  }
  .underline .RichEditor-styleButton {
    text-decoration: underline;
  }

  .linkControlDiv{
    display:inline-block;
  }

  .linkControlDiv button{
    border: none;
    background: #fff;
  }

  #breakLink{
    color:#E63C36;
  }

  .urlInputDiv input {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
    border-radius: 4px 0 0 4px;
  }

  .urlInputDiv button {
    background: #588157;
    border: 1px solid #588157;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
  }

  .urlInputDiv input:focus{
    
    outline: 1px solid #cce2ff;
  }

  